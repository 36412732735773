import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'home',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/resumen/ResumenEjecutivo'),
  },
  {
    path: '/informacion',
    name: 'informacion',
    component: () => import('@/views/resumen/InformacionOficial'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/poblacion/TequilaPoblacion'),
  },
  {
    path: '/reactivacion',
    name: 'reactivacion',
    component: () => import('@/views/reactivacion/ReactivacionEconomica'),
  },
  {
    path: '/sintomas',
    name: 'sintomas',
    component: () => import('@/views/sintomas/Informacion'),
  },
  {
    path: '/movilidad',
    name: 'movilidad',
    component: () => import('@/views/movilidad/MovilidadDashboard'),
  },
  {
    path: '/parking',
    name: 'parking',
    component: () => import('@/views/parking/ParkingPlaces'),
  },
  {
    path: '/analitica-carga',
    name: 'analiticaCarga',
    component: () => import('@/views/analiticaCarga/AnaliticaCargaDashboard'),
  },
  {
    path: '/seggitur',
    name: 'seggitur',
    component: () => import('@/views/seggitur/SeggiturDashboard'),
  },
  {
    path: '/redesTequila',
    name: 'redesTequila',
    component: () => import('@/views/redesSociales/RedesTequilaDashboard'),
  },
  {
    path: '/redesCuervo',
    name: 'redesCuervo',
    component: () => import('@/views/redesSociales/RedesMCDashboard'),
  },
  {
    path: '/pages/login',
    name: 'pages-login',
    component: () => import('@/views/pages/Login'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
